<template>
<div class="user-index">
  <el-page-header @back="goBack" :content="'创建评估'"></el-page-header>
  <div class="content">
    <h2 class="content-title">基本信息</h2>
    <el-form label-position="right" label-width="100px" class="form">
      <el-form-item label="机构名称：" prop="organization">
        <el-select class="select" v-model="organization" filterable placeholder="请选择机构名称">
          <el-option v-for="item in organizationList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="评估类型：">
        <p v-if="role == 3">区旗他评</p>
        <p v-if="role == 2 || role == 1">市级他评</p>
      </el-form-item>
      <el-form-item label="情况说明：" prop="content">
        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="content">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm" :loading="submitLoading" :disabled="canSubmit">立即创建</el-button>
      </el-form-item>
    </el-form>
  </div>
</div>
</template>

<script>
export default {
  name: "inspect-index",
  data() {
    return {
      id: "",
      title: "",
      organization: '',
      content: '',
      role: '',
      organizationList: [],
      submitLoading: false
    };
  },
  mounted() {
    this.init();
    this.role = localStorage.getItem('role');
  },
  computed: {
    canSubmit() {
      return !(this.organization);
    },
  },
  methods: {
    async init() {
      this.loading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/organ/list",
        data: {
          page: 1,
          limit: 10000
        }
      });
      this.loading = false;
      if (res.code == 0) {
        this.organizationList = res.data.list;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async submitForm() {
      this.submitLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/create",
        data: {
          organization_id: this.organization,
          explain: this.content
        }
      });
      this.submitLoading = false;
      if (res.code == 0) {
        this.$message({
          message: '创建成功',
          type: 'success'
        });
        this.$router.replace({
          path: "/inspect",
          query: {
            inspect_id: res.data.inspect_id
          }
        });
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  components: {
    // HelloWorld
  },
};
</script>

<style lang="scss" scoped>
.user-index {
  padding: 10px 4vw;
  min-height: calc(100vh - 150px);

  .title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
  }

  .content {
    overflow: hidden;
    width: 100%;
    background: #fff;
    margin-top: 20px;
    padding: 0 2vw;
    min-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;

    .content-title {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
    }

    .form {
      width: 60%;
    }

    .select {
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
.user-index {
  .el-form-item__content {
    text-align: left;
  }

  .el-form-item {
    margin-bottom: 32px;
  }

  .el-descriptions {
    font-size: 15px;
  }

  .el-descriptions-item__label:not(.is-bordered-label) {
    width: 80px;
    justify-content: flex-end;
    color: #000;
  }

  .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
    padding-bottom: 22px;
  }
}
</style>
